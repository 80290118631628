/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import globeBg from '../../images/backgrounds/globe-background.png'

import {
  Column,
  Columns,
  Image,
  LinkButton,
  SplitContent,
  Billboard,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import createImage from '../../utils/createImage'
import header from '../../components/Header'
import footer from '../../components/Footer'
import footerData from '../../data/footerData'
import headerData from '../../data/headerData'
import Tiles from '../../images/illustrations/tiles.svg'
import GraphUp from '../../images/illustrations/graph-up.svg'
import SocialNotifications from '../../images/illustrations/social-notifications.svg'
import SeventyFive from '../../images/illustrations/seventy-five.svg'
import Fifty from '../../images/illustrations/fifty.svg'
import SixtySix from '../../images/illustrations/sixty-six.svg'
import Meeting from '../../images/people/birds-eye-team-meeting.png'

const Home = () => {
  const FiftyIcon = createImage([Fifty], 99, 99)
  const SixtySixIcon = createImage([SixtySix], 99, 99)
  const SeventyFiveIcon = createImage([SeventyFive], 99, 99)
  const GraphUpIcon = createImage([GraphUp], 375, 250)
  const TilesIcon = createImage([Tiles], 375, 250)
  const SocialNotificationsIcon = createImage([SocialNotifications], 375, 250)

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'About Us | Clearlink',
          description:
            'SocialX by Clearlink is an extension to your engagement team, helping you reach your business goals using intelligence, strategy, and engagement.',
          canonical: 'https://clearlink.com/socialx/about',
          robots: 'follow,index',
        },
        path: '/socialx/about',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'SocialX | About',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="primary"
          image={
            <StaticImage
              src="../../images/people/birds-eye-team-meeting.png"
              alt="A birds eye view of Clearlinkers gathered in a meeting"
              placeholder="blurred"
            />
          }
          mobileImage={
            <img src={Meeting} alt="A group of Clearlinkers in a meeting" />
          }
          mainContent={
            <div>
              <Typography variant="h1">SocialX by Clearlink</Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Connecting Brands with Their Community on a Social Level
              </Typography>
              <LinkButton to="/socialx/contact" color="pink">
                Contact Us
              </LinkButton>
            </div>
          }
        />
        <div style={{ backgroundImage: `url(${globeBg})` }}>
          <VariableContent
            mainContent={
              <>
                <Typography variant="h4" className="mb-32 s1 center-desktop">
                  We engage with your followers and create unforgettable user
                  journeys that promote brand awareness, advocacy, retention,
                  and revenue.
                </Typography>
                <LinkButton to="/socialx/case-study" color="pink">
                  Case Study
                </LinkButton>
              </>
            }
            alignMainContent="center"
          ></VariableContent>
        </div>
        <VariableContent alignMainContent="center">
          <Stack spacing="l">
            <Typography
              variant="h2"
              style={{ margin: '0 auto 50px', textAlign: 'center' }}
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              How We Socialize
            </Typography>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              className="narrow center"
            >
              <Typography style={{ margin: '0 auto 32px' }}>
                No brand wants to miss out on the latest trends, topics, and
                tweets. Even the fear of missing out can freeze a brand’s social
                progress. That’s why SocialX by Clearlink is an extension to
                your engagement team, helping you reach your business goals
                using our three social-savvy pillars—intelligence, strategy, and
                engagement. FOMO no mo’.
              </Typography>
            </div>
            <Columns>
              <Column
                backgroundColor="white"
                image={<Image data={GraphUpIcon} />}
              >
                <Typography variant="h4" className="color-dark">
                  Social Intelligence
                </Typography>
                <Typography>
                  From hashtags to what’s trending, we listen to your online
                  customers to deliver actionable insights and consumer-first
                  strategy through both real-time and historical research.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                image={<Image data={TilesIcon} />}
              >
                <Typography variant="h4" className="color-dark">
                  Social Strategy
                </Typography>
                <Typography>
                  We partner with you to develop a strategy tailored to your
                  organizational goals and objectives. We monitor vertical,
                  competitor, and social media trends and create a social
                  content calendar to encourage community conversation and give
                  you more than 15 minutes of fame.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                image={<Image data={SocialNotificationsIcon} />}
              >
                <Typography variant="h4" className="color-dark">
                  Social Engagement
                </Typography>
                <Typography>
                  We’ve leveraged our social intelligence to develop a social
                  strategy—now it’s time to create engaging social content that
                  is timely and relevant to the conversation while increasing
                  customer satisfaction, driving brand retention and catapulting
                  brand loyalty.
                </Typography>
              </Column>
            </Columns>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <LinkButton to="/contact" color="pink" className="center-desktop">
                Learn More
              </LinkButton>
            </div>
          </Stack>
        </VariableContent>
        <VariableContent backgroundColor="light" id="see-how">
          <Stack spacing="l">
            <div className="narrow medium center">
              <span
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography
                  className="center-desktop"
                  variant="h2"
                  style={{ margin: '0 auto 50px' }}
                >
                   The State of Social
                </Typography>
                <Typography
                  className="center-desktop"
                  style={{ margin: '0 auto 50px' }}
                >
                  With nearly 5 billion people on social media today, it’s no
                  surprise that brands must own their social presence and
                  community. The average person uses social media for almost 2.5
                  hours a day, making social media the prime space for news,
                  advertisements, and marketing. Social media is changing how
                  brands connect with their consumers, and SocialX has the
                  recipe to convert online engagement into real-life profits.
                </Typography>
              </span>
            </div>
            <Columns leftAligned={false} mobileCarousel>
              <Column
                image={<Image data={SeventyFiveIcon} />}
                leftAligned={false}
              >
                <Typography variant="h6">
                  71% of consumers who have had a positive experience with a
                  brand on social media will recommend it.
                </Typography>
              </Column>
              <Column image={<Image data={SixtySixIcon} />} leftAligned={false}>
                <Typography variant="h6">
                  66% of brand communities say that their social community has
                  led to increased loyalty to the brand.
                </Typography>
              </Column>
              <Column image={<Image data={FiftyIcon} />} leftAligned={false}>
                <Typography variant="h6">
                  50% of users in the US have made a purchase via social.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
        <SplitContent
          image={
            <StaticImage
              src="../../images/people/three-tablets.png"
              alt="Three people gathered in a circle looking at tablets"
              placeholder="blurred"
            />
          }
          mainContent={
            <div>
              <Typography variant="h1">Let’s Get Social</Typography>
              <Typography style={{ marginBottom: '26px' }}>
                SocialX evolves a brand’s social media presence to create
                genuine connections with its consumers. While other social
                agencies are ready to retweet, we’re prepared to go viral with
                our pillars to social success—intelligence, strategy, and
                engagement. Join us in making your brand the next trending
                topic.
              </Typography>
              <LinkButton to="/contact" color="pink">
                Connect With Us
              </LinkButton>
            </div>
          }
        />
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
