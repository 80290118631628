export default function createImage(
  imagePath,
  width,
  height,
  layout = 'constrained'
) {
  const sources = imagePath.map((path, index) => ({
    sizes: `(min-width: ${width}px) ${width}px, 100vw`,
    srcSet: path,
    type: index === 0 ? 'image/webp' : 'image/jpeg',
  }))

  return {
    height,
    width: width || '100%',
    layout,
    images: {
      sources,
    },
    fallback: {
      sizes: `(min-width: ${width}px) ${width}px, 100vw`,
      srcSet: imagePath[0],
      src: imagePath[0],
    },
    placeholder: {
      fallback: imagePath[0],
    },
  }
}
